import React, { useState } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import jwt_decode from "jwt-decode";
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


function FilterInput() {
// const FilterInput = ({ onFilterSubmit }) => {
  var condition;
  // const [interests, setInterests] = useState('');
  // const [submitted, setSubmitted] = useState(false);
  // const [result, setResult] = useState('');
  const [user, setUser] = useState('');
  const [loading] = useState('');

  const responseMessage = (response) => {
    console.log(response);
    var token = response.credential;
    var decoded = jwt_decode(token);
    console.log(decoded.email);
    setUser(decoded.email);
};
const errorMessage = (error) => {
    console.log(error);
};

// Submit button toast message
const submitNotification = () => {
  toast.success("Submitted Successfully! You'll receive updates from us every monday, starting next week.", { position: "bottom-center" }); 
};

// Insert Data to MongoDB
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const valuesArray = inputValue.split(',').map((value) => value.trim());

    try {
      await axios.post('https://coyote.onrender.com/filterWords', { values: valuesArray, email: user });
      console.log('Values stored successfully!');
    } catch (error) {
      console.error('Error storing values:', error.response.data);
    }
  };

  if(!user) {
    condition = <div className="bg-slate-100 min-h-screen flex flex-col justify-center items-center">
                    <img src="/light-coyote.png" alt="Placeholder logo" className="h-20 mb-8"/>
                    <label htmlFor="interests" className="text-center p-4 font-montserrat font-medium text-black w-3/4 mb-2 text-4xl">Notification service that monitors the web based on your interests!</label>
                    <br/>
                    <GoogleLogin onSuccess={responseMessage} onError={errorMessage} />
                </div>
  }

  else {
    condition = <div>
      <div className="bg-slate-100 min-h-screen flex flex-col justify-center items-center">
      <img src="/light-coyote.png" alt="Placeholder logo" className="h-20 mb-8"/>
      {/* <label htmlFor="interests" className="block text-xl text-center p-4 font-montserrat font-medium text-black w-3/5 mb-2 text-3xl">Stay updated with 3 interests that matter the most (more niche and specific, the better). We're bringing them to your inbox!</label> */}
      <label htmlFor="interests" className="block text-xl text-center p-4 font-montserrat font-medium text-black w-3/5 mb-2 text-3xl">List 3 topics that matter to you, we'll send the relevant updates to your inbox weekly (more specific, better results - eg: <span className='line-through'>startups</span> ={'>'} edtech)!</label>
      <br/>
      <form onSubmit={handleFormSubmit} className="bg-gray-400 rounded-md p-8 shadow-lg">
        <label className="block text-black-500 text-xl font-montserrat font-medium mb-4">
        Enter the 3 topics:
        </label>

        <textarea
            id="interests"
            name="interests"
            className="border bg-gray-200 rounded-md font-montserrat font-medium py-2 px-3 mb-4 w-full"
            placeholder="e.g. edtech, llm assisted education, gen-ai in edtech."
            value={inputValue}
            onChange={handleInputChange} 
            rows={4}
            cols={50}   
          />
        <button
          type="submit"
          onClick={submitNotification}
          className="bg-[#E25542] hover:bg-red-500 text-black font-montserrat font-semibold py-2 px-4 rounded">
          Submit
        </button>
        {loading && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-75">
          <div className="loader animate-spin ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 duration-500"></div>
        </div>
      )}
      </form>
      <br/>
      {/* <label htmlFor="interests" className="text-center text-white mb-2 p-2 font-mono w-3/4 font-semibold text-xl">Thank you, keep an eye on your inbox!</label> */}

    </div>
    </div>
  }

  return (
  <div> 
    {condition}
    <ToastContainer/>
  </div>
  );
};

export default FilterInput;
